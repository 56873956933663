import React, { useState } from 'react'
import { useDispatch } from 'react-redux'
import { sendContactFormThunk } from '../../store/reducers/actions'
import s from './ContactForm.module.css'
import MyBtn from '../ui/MyBtn/MyBtn'
import CustomModal from '../ui/customModal/customModal'
import RedBtn from '../ui/redBtn/RedBtn'

const ContactForm = () => {
  const dispatch = useDispatch()
  const [formData, setFormData] = useState({ name: '', email: '', phone: '', message: '' })
  const [errors, setErrors] = useState({})
  const [isPopupOpen, setIsPopupOpen] = useState(false)
  const [isLoading, setIsLoading] = useState(false)

  const handleChange = e => {
    const { name, value } = e.target
    setFormData({ ...formData, [name]: value })
    setErrors({ ...errors, [name]: '' })
  }

  const validateForm = () => {
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/
    const newErrors = {}

    if (!formData.name.trim()) newErrors.name = 'ФИО обязательно для заполнения'
    if (!emailRegex.test(formData.email)) newErrors.email = 'Некорректный адрес электронной почты'
    if (!formData.phone.trim()) newErrors.phone = 'Номер телефона обязателен для заполнения'
    if (!formData.message.trim()) newErrors.message = 'Сообщение обязательно для заполнения'

    return newErrors
  }

  const handleSubmit = async e => {
    e.preventDefault()
    const validationErrors = validateForm()
    if (Object.keys(validationErrors).length > 0) {
      setErrors(validationErrors)
      return
    }

    setIsLoading(true)
    try {
      await dispatch(sendContactFormThunk(formData))
      setIsPopupOpen(true)
      setFormData({ name: '', email: '', phone: '', message: '' })
    } catch (error) {
      alert('Произошла ошибка. Пожалуйста, попробуйте еще раз.')
    } finally {
      setIsLoading(false)
    }
  }

  return (
    <div className='container'>
      <CustomModal isModalOpen={isPopupOpen} setIsModalOpen={setIsPopupOpen}>
        <div className='title'>Успешно!</div>
        <div className='modal-section'>Ваша заявка была отправлена.</div>
        <div className='modal-section'>
          <MyBtn medium title={'Ok'} onClick={() => setIsPopupOpen(false)} />
        </div>
      </CustomModal>

      <div className={s.contact_form}>
        {/* left side */}
        <div className={s.info}>
          <h3>Адрес</h3>
          <p>Репрезентативный Офис: Glandore Office Building, Fitzwilliam Hall, 26 Fitzwilliam Pl, Dublin 2, D02 T292, Ireland</p>
          <p className={s.gray}>(Обслуживание физических лиц осуществляется по предварительной записи.)</p>
          <p>Центральный офис: Bonovo Road — Fomboni Island of Mohéli — Comoros Union</p>
          <h3>Режим работы</h3>
          <p>Пн-Пт 09:00–18:00, Обед 13:00–14:00</p>
          <h3>Электронная почта</h3>
          <p>
            <a href='mailto:info@mezhdunarodnaya-sberkassa.com'>info@mezhdunarodnaya-sberkassa.com</a>
          </p>
          <h3>Сайт</h3>
          <p>
            <a href='http://www.mezhdunarodnaya-sberkassa.com'>www.mezhdunarodnaya-sberkassa.com</a>
          </p>
          <h3>Телефон службы комплаенс</h3>
          <p>
            <a href='tel:+353818883938'>+353 81 888 3938</a>
          </p>
        </div>

        {/* right side [inputs] */}
        <form onSubmit={handleSubmit} className={s.input_form} id='contact-form'>
          <div className={s.input_block}>
            <label className={s.lable}>ФИО</label>
            <input
              className={s.input}
              placeholder='Введите ФИО'
              type='text'
              name='name'
              value={formData.name}
              onChange={handleChange}
            />
            {errors.name && <span className={s.error}>* {errors.name}</span>}
          </div>

          <div className={s.input_block}>
            <label className={s.lable}>E-mail</label>
            <input
              className={s.input}
              placeholder='Введите Email'
              type='text'
              name='email'
              value={formData.email}
              onChange={handleChange}
            />
            {errors.email && <span className={s.error}>* {errors.email}</span>}
          </div>

          <div className={s.input_block}>
            <label className={s.lable}>Телефон</label>
            <input
              className={s.input}
              placeholder='Введите номер телефона'
              type='tel'
              name='phone'
              value={formData.phone}
              onChange={handleChange}
            />
            {errors.phone && <span className={s.error}>* {errors.phone}</span>}
          </div>

          <div className={s.input_block}>
            <label className={s.lable}>Обратная связь</label>
            <textarea
              className={s.input}
              placeholder='Введите сообщение'
              type='text'
              name='message'
              value={formData.message}
              onChange={handleChange}
              rows={10}
            />
            {errors.message && <span className={s.error}>* {errors.message}</span>}
          </div>

          <RedBtn type='submit' btnLable={isLoading ? 'Отправка...' : 'Отправить'} fullWidth />
        </form>
      </div>
    </div>
  )
}

export default ContactForm
