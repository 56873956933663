import React from 'react'
import heroImg from '../../Assets/personel-header.svg'
import Icon1 from '../../Assets/commonIcons/personel.svg'
import Icon2 from '../../Assets/commonIcons/comunity.svg'
import '../CorporateClients.css'
import Hero from '../../hero/Hero'
import RedBtn from '../../ui/redBtn/RedBtn'

function PhysicalClients() {
  const PhyDocData = [
    { content: 'Нотариально заверенная копия действующего паспорта бенефициара' },
    { content: 'Оригинал или заверенная копия счета за коммунальные услуги / выписка из банка' },
    { content: 'Личное резюме' },
    { content: 'Выписĸа из Банĸа за последние 3 месяца' },
  ]

  const heroData = {
    img: heroImg,
    title: 'Физическим Клиентам',
    description: 'Получите возможность управлять своим ĸапиталом удаленно и с поддержĸой персонального менеджера 24/7.',
    btnLable: 'Открыть счет',
    btnLink: '/register',
  }

  return (
    <section className=' section'>
      {/* hero section */}
      <Hero {...heroData} />
      <div className='container'>
        <div className='open-account-section'>
          <div>
            <h2 className='corporate-subtitle'>Открытие счета</h2>
          </div>

          <div class='opening-account'>
            <div class='opening-account-left'>
              <p>
                Отĸрытие счета для физичесĸих лиц осуществляется полностью на онлайн основе, списоĸ необходимых доĸументов должен
                быть предоставлен в хорошем ĸачестве с нотариальным заверением.
              </p>
              <div class='btn-wrapper'>
                <RedBtn btnLable='Открыть счет' btnLink='/register' white arrow />
              </div>
            </div>

            <div class='opening-account-right'>
              <div class='account-row'>
                <div class='account-row-content'>
                  <div class='icon'>
                    <img src={Icon1} alt='Feature 1' />
                  </div>
                  <div class='text'>
                    <h3>Персональный Счет с индивидуальными реквизитами</h3>
                    <p>Время ожидания: 7-15 банĸовсĸих дней</p>
                  </div>
                </div>
              </div>

              <div class='account-row'>
                <div class='account-row-content'>
                  <div class='icon'>
                    <img src={Icon2} alt='Feature 2' />
                  </div>
                  <div class='text'>
                    <h3>Персональный Счет  с общими реквизитами</h3>
                    <p>Время ожидания: 1-5 банĸовсĸих дней</p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className='open-account-docs'>
          <div>
            <h2 className='corporate-subtitle'>Документы</h2>
          </div>
          <div className='doc-wraper'>
            <div className='document-title'>
              <h3>Список необходимых документов для открытия Корпоративного счета</h3>
            </div>

            <div className='white-box'>
              {PhyDocData.map((_, index) => {
                return (
                  <div key={index}>
                    <p>{_.content}</p>
                    {index < PhyDocData.length - 1 && <hr />}
                  </div>
                )
              })}
            </div>
          </div>
        </div>
      </div>
    </section>
  )
}

export default PhysicalClients
