import React from 'react'
import s from './FlexCard.module.css'
import RedBtn from '../../ui/redBtn/RedBtn'

// payment methods icons
import visa from '../../Assets/otherPage/paymentMethods/visa.png'
import mastercard from '../../Assets/otherPage/paymentMethods/mastercard.png'
import unionPay from '../../Assets/otherPage/paymentMethods/union-pay.png'
import applePay from '../../Assets/otherPage/paymentMethods/apple-pay.png'
import discover from '../../Assets/otherPage/paymentMethods/discover.png'
import jcb from '../../Assets/otherPage/paymentMethods/JCB.png'
import amex from '../../Assets/otherPage/paymentMethods/american-express.png'

const paymentMethodsIcons = [
  { src: visa, alt: 'Visa' },
  { src: mastercard, alt: 'MasterCard' },
  { src: unionPay, alt: 'UnionPay' },
  { src: applePay, alt: 'Apple Pay' },
  { src: discover, alt: 'Discover' },
  { src: jcb, alt: 'JCB' },
  { src: amex, alt: 'Amex' },
]
const duplicatedIcons = [...paymentMethodsIcons, ...paymentMethodsIcons]

const FlexCard = ({
  title,
  description,
  img,
  btnLable,
  btnLink,
  bgGradient = false,
  textFirst = true,
  showPaymentMethods = false,
}) => {
  return (
    <div className={`${s.component} container`}>
      <h2>{title}</h2>
      <div className={`${s.flex} ${textFirst ? '' : s.reverse}`}>
        <div className={`${s.left} ${bgGradient ? s.bg_gradient : s.bg_white}`}>
          <p>{description}</p>
          <div className={s.btn}>
            <RedBtn btnLable={btnLable} btnLink={btnLink} white={bgGradient} arrow />
          </div>
        </div>
        <img src={img} alt={title} className={s.right} />
      </div>
      {showPaymentMethods && (
        <div className={s.scroll_container}>
          <div className={s.scroll_content}>
            {duplicatedIcons.map(icon => (
              <div key={icon.alt} className={s.icon}>
                <img src={icon.src} alt={icon.alt} />
              </div>
            ))}
          </div>
        </div>
      )}
    </div>
  )
}

export default FlexCard
