import React from 'react'
import s from './HeroWithBanner.module.css'
import RedBtn from '../ui/redBtn/RedBtn'

const HeroWithBanner = ({ heroImage, title, desc, btnLable, btnLink, bgGradient = false, textLeft = false }) => {
  return (
    <div className={`${s.hero} container`}>
      <h1>{title}</h1>
      <div className={s.banner}>
        <img src={heroImage} alt={title} />
        <div className={`${s.over_content_wrapper} ${textLeft ? s.left_align : s.right_align}`}>
          <div className={`${s.over_content} ${bgGradient ? s.gradient : s.white}`}>
            <p dangerouslySetInnerHTML={{ __html: desc }}></p>
            <div className={s.btn}>
              <RedBtn btnLable={btnLable} btnLink={btnLink} white={bgGradient} arrow />
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default HeroWithBanner
