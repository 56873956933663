import React from 'react'
import s from './CryptoOperations.module.css'
import heroImage from '../Assets/crypto-currency-page/hero-image.jpeg'
import paymentServicesImg from '../Assets/crypto-currency-page/payment-services.jpeg'
import HeroWithBanner from '../hero-with-banner/HeroWithBanner'
import RedBtn from '../ui/redBtn/RedBtn'

const heroData = {
  heroImage: heroImage,
  title: 'Операции с Криптовалютой',
  desc: 'Мы поддерживаем ĸонцепцию цифровизации и децентрализации валюты в современном мире. Также Международная СберКасса предоставляет возможность криптообменных операций. У нас нет ограничений на объемы обмена криптовалют, поэтому вы можете свободно конвертировать любую сумму. Международная СберКасса взимает до 2,5 процентов за конвертацию в зависимости от объема.',
  btnLable: 'Открыть счет',
  btnLink: '/register',
  bgGradient: true,
  textLeft: true,
}

function CryptoOperations() {
  return (
    <div className={s.crypto_currency_page}>
      {/* hero section */}
      <HeroWithBanner {...heroData} />

      {/* crypto payment gateway */}
      <div className={`${s.crypto_payment} container`}>
        <h2>Международная СберКасса предоставляет платежный шлюз для криптоплатежей</h2>
        <div className={s.btn}>
          <RedBtn btnLable='Открыть счет' btnLink='/register' arrow />
        </div>
      </div>

      {/* payment services */}
      <div className={`${s.payment_services} container`}>
        <h3 className={s.title}>
          Это сервис, который позволяет продавцам обрабатывать платежи в криптовалютах. Он действует как посредник и выполняет 6
          основных функций:
        </h3>
        <div className={s.flex}>
          <img src={paymentServicesImg} className={s.left} alt='crypto currency' />
          <div className={s.right}>
            <div className={s.line}>
              <div className={s.gradient_card}>
                <p>01</p>
                <p>Отправляет данные транзакции в блокчейн и регистрирует подтверждения от узлов</p>
              </div>
              <div className={s.white_card}>
                <p>02</p>
                <p>Предоставляет подтверждение обработки платежа для обеих сторон</p>
              </div>
            </div>
            <div className={s.line}>
              <div className={s.gradient_card}>
                <p>03</p>
                <p>Регистрирует данные плательщика</p>
              </div>
              <div className={s.white_card}>
                <p>04</p>
                <p>Конвертирует полученные монеты в фиатные деньги (по запросу мерчанта)</p>
              </div>
            </div>
            <div className={s.line}>
              <div className={s.gradient_card}>
                <p>05</p>
                <p>Принимает платежи от покупателя</p>
              </div>
              <div className={s.white_card}>
                <p>06</p>
                <p>Переводит платежи мерчанту</p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default CryptoOperations
