import React, { useState } from 'react'
// import s from './Home.module.css'
import './Home.css'
import MyBtn from '../ui/MyBtn/MyBtn'
import BankImage from '../Assets/homeImages/bankImg.svg'
import MyInput from '../ui/MyInput/MyInput'
import { useDispatch } from 'react-redux'
import { sendContactFormThunk } from '../../store/reducers/actions'
import CustomModal from '../ui/customModal/customModal'
import heroBanner from '../Assets/bannerImages/homeBanner.svg'
import Reading from '../Assets/homeImages/personReading.svg'
import HeroWithBanner from '../hero-with-banner/HeroWithBanner'
import RedBtn from '../ui/redBtn/RedBtn'

const Home = () => {
  const dispatch = useDispatch()
  const [formData, setFormData] = useState({
    name: '',
    email: '',
    phone: '',
  })

  const [errors, setErrors] = useState({
    email: '',
    phone: '',
  })

  const [isPopupOpen, setIsPopupOpen] = useState(false)

  const validatePhone = phone => {
    const phoneRegex = /^\+?[1-9]\d{1,14}$/ // International phone number regex (E.164 format)
    return phoneRegex.test(phone)
  }

  const validateEmail = email => {
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/ // Basic email validation regex
    return emailRegex.test(email)
  }

  const handleChange = e => {
    const { name, value } = e.target
    setFormData({
      ...formData,
      [name]: value,
    })
  }

  const handleBlur = e => {
    const { name, value } = e.target

    if (name === 'phone') {
      if (!validatePhone(value)) {
        setErrors(prevErrors => ({
          ...prevErrors,
          phone: 'Пожалуйста, введите номер телефона (например, +123456789).',
        }))
      } else {
        setErrors(prevErrors => ({
          ...prevErrors,
          phone: '',
        }))
      }
    }

    if (name === 'email') {
      if (!validateEmail(value)) {
        setErrors(prevErrors => ({
          ...prevErrors,
          email: 'Пожалуйста, введите действительный адрес электронной почты.',
        }))
      } else {
        setErrors(prevErrors => ({
          ...prevErrors,
          email: '',
        }))
      }
    }
  }

  const handleSubmit = async e => {
    e.preventDefault()

    // Final validation check before submitting
    if (errors.phone || errors.email || !validatePhone(formData.phone) || !validateEmail(formData.email)) {
      alert('Пожалуйста, исправьте ошибки перед отправкой.')
      return
    }

    try {
      await dispatch(sendContactFormThunk(formData))
      setIsPopupOpen(true)
    } catch (error) {
      alert('Произошла ошибка. Попробуйте еще раз.')
    }
  }

  const heroData = {
    heroImage: heroBanner,
    title: 'Банк для вашего финансового успеха',
    desc: 'Наш банк способствует экономическому и социальному развитию резидентов стран СНГ, способствуя их успешному выходу на международные рынки. Мы поддерживаем рост и укрепление бизнеса наших клиентов, предоставляя им для этого широкий спектр высококачественных банковских услуг и внедряя передовые банковские технологии.',
    btnLable: 'Подробнее',
    btnLink: '/aboutBank',
    bgGradient: true,
    textLeft: false,
  }

  return (
    <section className='section'>
      {/* hero banner */}
      <HeroWithBanner {...heroData} />

      {/* quote container */}
      <div className='quote container'>
        <p>
          Международная СберКасса — это способ уверенного продвижения <span>Вашего Бизнеса в сторону успеха</span>.
        </p>
      </div>

      {/* about bank today */}
      <div class='about_bank_today container'>
        {/* top flex section */}
        <div className='about_bank_top'>
          <div className='left'>
            <h3>Банк сегодня</h3>
            <p>
              Международная СберКасса осуществляет операции с Мая 2022 года. Мы обслуживаем индивидуальных и корпоративных
              клиентов различных форм собственности и направлений деятельности.
            </p>
          </div>
          <img src={BankImage} alt='About Bank Today' className='right' />
        </div>
        {/* overview points */}
        <div className='overview_points'>
          <div className='line'>
            <p className='number'>01.</p>
            <p className='text'>
              Экономическое развитие Банка основывается на предоставлении клиентам широкого спектра высокотехнологичных банковских
              продуктов и услуг, соответствующих международным стандартам.
            </p>
          </div>
          <div className='line'>
            <p className='number'>02.</p>
            <p className='text'>
              Банк лоялен к каждому русскоязычному клиенту и гарантирует успешность его платежей, независимо от того, в какой
              точке мира он находится.
            </p>
          </div>
          <div className='line'>
            <p className='number'>03.</p>
            <p className='text'>
              Широкая сеть партнерских банков-корреспондентов позволяет осуществлять платежи по всему миру с учетом любых
              пожеланий клиента.
            </p>
          </div>
          <div className='line'>
            <p className='number'>04.</p>
            <p className='text'>
              Вместе с Международной СберКассой вы сможете с уверенностью совершать сделки, инвестировать в недвижимость, а также
              приумножать свой капитал.
            </p>
          </div>
        </div>
        {/* Button */}
        <div className='btn'>
          <RedBtn btnLable='Подробнее' btnLink='/aboutBank' arrow />
        </div>
      </div>

      {/* advantages package */}
      <div className='advantages_package container'>
        {/* header section */}
        <div className='header_section'>
          <div className='left'>
            <h2>Наш пакет преимуществ</h2>
            <p>
              Вы откроете для своего бизнеса новые горизонты с нашим эксклюзивным пакетом финансовых услуг, созданного для вашей
              безопасности и успеха. Выберите нас и вы перенесете ваши финансы в мир стабильности и процветания.
            </p>
          </div>

          {/* for lg devices only */}
          <div className='right'>
            <RedBtn btnLable='Открыть счет' btnLink='/register' arrow />
          </div>
        </div>

        {/* advantages cards */}
        <div className='advantages_flex'>
          <img src={Reading} alt='advantages package' className='left' />
          {/* grid container */}
          <div className='right grid_container'>
            <div className='card bg_gradient'>
              <h3>01. Глобальная платформа</h3>
              <p>
                Поддерживаем предпринимателей стран СНГ и обеспечиваем их экономическое развитие на международном рынке,
                обеспечивая им доступ к передовым банковским услугам мирового масштаба.
              </p>
            </div>
            <div className='card'>
              <h3>02. Широкий спектр услуг</h3>
              <p>
                Предлагаем клиентам разнообразные банковские услуги высокого качества, включая услуги по финансированию,
                инвестированию, и обслуживанию счетов
              </p>
            </div>
            <div className='card'>
              <h3>03. Инновационные технологии</h3>
              <p>Используем современные банковские технологии, чтобы обеспечить удобство и безопасность для наших клиентов</p>
            </div>
            <div className='card bg_gradient'>
              <h3>04. Безопасность и надежность</h3>
              <p>Осуществляем банковские операции с высоким уровнем безопасности и надежности, защищая интересы наших клиентов</p>
            </div>
          </div>
        </div>
        {/* for lg devices only */}
        <div className='mobile_btn'>
          <RedBtn btnLable='Открыть счет' btnLink='/register' arrow />
        </div>
      </div>

      {/* Qoute Container */}
      <div className='quote container'>
        <p>
          Для нас <span>каждый клиент</span> — <span>важный </span>клиент!
        </p>
      </div>

      <div className='container'>
        <CustomModal isModalOpen={isPopupOpen} setIsModalOpen={setIsPopupOpen}>
          <div className='title'>Успешно!</div>
          <div className='modal-section'>Ваша заявка была отправлена.</div>
          <div className='modal-section'>
            <MyBtn
              medium
              title={'Ok'}
              onClick={() => {
                setIsPopupOpen(false)
              }}
            />
          </div>
        </CustomModal>

        <div className='home-contact-container'>
          <p className='home-contact-title'>Воспользуйтесь бесплатной консультацией специалиста</p>

          <form className='contact-form' onSubmit={handleSubmit}>
            <div className='input-block'>
              <div className='label'>ФИО</div>
              <MyInput id='name' name='name' value={formData.name} onChange={handleChange} placeholder='Введите ФИО' />
            </div>
            <div className='input-block'>
              <div className='label'>E-MAIL</div>
              <MyInput
                id='email'
                name='email'
                value={formData.email}
                onChange={handleChange}
                onBlur={handleBlur}
                placeholder='Введите Email'
              />
              {errors.email && <p className='home-contact-error'>{errors.email}</p>}
            </div>
            <div className='input-block'>
              <div className='label'>Телефон</div>
              <MyInput
                id='phone'
                name='phone'
                placeholder='Введите номер телефона'
                value={formData.phone}
                onChange={handleChange}
                onBlur={handleBlur}
              />
              {errors.phone && <p className='home-contact-error'>{errors.phone}</p>}
            </div>

            <RedBtn type='submit' btnLable='Отправить' fullWidth />
          </form>
        </div>
      </div>
    </section>
  )
}

export default Home
