import React from 'react'
import s from './Hero.module.css'
import RedBtn from '../ui/redBtn/RedBtn'

const Hero = ({ img, title, description, btnLable, btnLink }) => {
  return (
    <div className='container'>
      <h1 className={s.title}>{title}</h1>
      <div className={s.hero}>
        <img src={img} alt={title} />
        <div className={s.hero_content}>
          <p dangerouslySetInnerHTML={{ __html: description }}></p>
          <RedBtn btnLable={btnLable} btnLink={btnLink} arrow />
        </div>
      </div>
    </div>
  )
}

export default Hero
