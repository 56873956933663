import React from 'react'
import heroImg from '../Assets/corporateHeader.svg'
import Icon1 from '../Assets/commonIcons/personel.svg'
import Icon2 from '../Assets/commonIcons/comunity.svg'
import './CorporateClients.css'
import Hero from '../hero/Hero'
import RedBtn from '../ui/redBtn/RedBtn'

function CorporateClients() {
  const docData = [
    { content: 'Свидетельство о регистрации' },
    { content: 'Копия учредительных документов' },
    {
      content: (
        <>
          Документ<span className='doc-light'>(ы)</span>, подтверждающий назначение директора
          <span className='doc-light'>(ов) </span>и секретаря компании <span className='doc-light'>(при наличии)</span>
        </>
      ),
    },
    { content: 'Копия корпоративного реестра (который включает в себя реестр акционеров, директоров и секретаря)' },
    { content: 'Документ, подтверждающий местонахождение юридического адреса компании' },
    { content: 'Свидетельство о хорошей репутации (в случае, если компания была зарегистрирована более 12 месяцев назад)' },
    { content: 'Действующая лицензия (если применимо)' },
    { content: 'Нотариально заверенная копия действующего паспорта бенефициара' },
    { content: 'Оригинал или заверенная копия счета за коммунальные услуги / выписка из банка' },
    { content: 'Доверенность (при наличии)' },
    {
      content:
        'Полностью заполненная и подписанная анкета для открытия корпоративного банковского счета в Международной СберКассе',
    },
    { content: 'Личное резюме' },
  ]

  const heroData = {
    img: heroImg,
    title: 'Корпоративным Клиентам',
    description:
      'Международная СберКасса предоставляет возможность ĸорпоративным ĸлиентам осуществлять поставленные цели и задачи в ĸратчайшие сроĸи. </br></br> Изучив Вашу деятельность и задачи, мы сможем предложить наиболее выгодные решения, а таĸже увеличить доходность вашей деятельности, оптимизировав налогообложение.',
    btnLable: 'Открыть счет',
    btnLink: '/register',
  }

  return (
    <section className='section'>
      {/* hero section */}
      <Hero {...heroData} />

      <div className='corporate-container container grid'>
        <div>
          <h2 className='corporate-subtitle'>Открытие счета</h2>
        </div>

        <div class='opening-account'>
          <div class='opening-account-left'>
            <p>
              Процесс отĸрытия Корпоративного Счета варьируется от 3-х до 20-ти банĸовсĸих дней, в зависимости от запрашиваемого
              типа Счета.
            </p>
            <div class='btn-wrapper'>
              <RedBtn btnLable='Открыть счет' btnLink='/register' white arrow />
            </div>
          </div>

          <div class='opening-account-right'>
            <div class='account-row'>
              <div class='account-row-content'>
                <div class='icon'>
                  <img src={Icon1} alt='Feature 1' />
                </div>
                <div class='text'>
                  <h3>Корпоративный счет с предоставлением личного IBAN номера</h3>
                  <p>Время ожидания: 3-5 банĸовсĸих дней</p>
                </div>
              </div>
            </div>

            <div class='account-row'>
              <div class='account-row-content'>
                <div class='icon'>
                  <img src={Icon2} alt='Feature 2' />
                </div>
                <div class='text'>
                  <h3>Общий корпоративный счет</h3>
                  <p>Время ожидания: 5-20 банĸовсĸих дней</p>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className='open-account-docs'>
          <h2 className='corporate-subtitle'>Документы</h2>
        </div>
        <div className='doc-wraper'>
          <div className='document-title'>
            <h3>Список необходимых документов для открытия Корпоративного счета</h3>
          </div>

          <div className='white-box'>
            {docData.map((_, index) => {
              return (
                <div key={index}>
                  <p>{_.content}</p>
                  {index < docData.length - 1 && <hr />}
                </div>
              )
            })}
          </div>
        </div>
      </div>
    </section>
  )
}

export default CorporateClients
