import React, { useState } from 'react'
import s from './RedBtn.module.css'
import redRightArrow from '../../Assets/icons/red-right-arrow.svg'
import whiteRightArrow from '../../Assets/icons/white-right-arrow.svg'

const RedBtn = ({ btnLable, btnLink, arrow = false, fullWidth = false, type = 'button', white = false }) => {
  const [whiteArrow, setWhiteArrow] = useState(white)

  const isSubmitType = type === 'submit'
  return (
    <div>
      {isSubmitType ? (
        <button type={type} className={`${s.button} ${arrow ? s.arrow_padding : ''} ${fullWidth ? s.full_width : ''}`}>
          <span>{btnLable}</span>
          {arrow && <img src={redRightArrow} alt='arrow' />}
        </button>
      ) : (
        <a
          href={btnLink}
          className={`${s.button} ${arrow ? s.arrow_padding : ''} ${fullWidth ? s.full_width : ''} ${white ? s.white : ''}`}
          onMouseEnter={() => setWhiteArrow(white? false: true)}
          onMouseLeave={() => setWhiteArrow(white)}>
          <span>{btnLable}</span>
          {arrow && (
            <>
              <img src={redRightArrow} alt='red arrow' className={whiteArrow ? s.hidden : s.visible} />
              <img src={whiteRightArrow} alt='white arrow' className={whiteArrow ? s.visible : s.hidden} />
            </>
          )}
        </a>
      )}
    </div>
  )
}

export default RedBtn
