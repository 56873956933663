import React from 'react'
import s from './Contact.module.css'
import ContactForm from '../Contact Form/ContactForm'
import Hero from '../hero/Hero'
import contactImg from '../Assets/contact-hero.png'

const Contact = () => {
  const heroData = {
    img: contactImg,
    title: 'Контакты',
    description:
      'Адреса отделений и контактные номера банка. В этом разделе вы можете найти номера телефонов и электронные адреса для связи с банком. А так же вы можете оставить нам свои обращения или предложения.',
    btnLable: 'Cвязаться',
    btnLink: '#contact-form',
  }
  return (
    <div className={s.contact_page}>
      {/* hero section */}
      <Hero {...heroData} />

      {/* contact form */}
      <div className={s.contact_us}>
        <ContactForm />
      </div>
    </div>
  )
}

export default Contact
