import React, { useState, useRef, useEffect } from 'react';
import { Link, NavLink, useLocation } from 'react-router-dom';
import './Header.css';
import logo from '../Assets/headerLogo.svg';
import arrowUpRed from '../Assets/arrow-up-red.svg';
import arrowDownRed from '../Assets/arrow-down-red.svg';
import arrowDownBlue from '../Assets/arrow-down-blue.svg';
import arrowUpBlue from '../Assets/arrow-up-blue.svg';
import MyBtn from "../ui/MyBtn/MyBtn";
import RedBtn from '../ui/redBtn/RedBtn';

const Header = () => {
    const [dropdownOpen, setDropdownOpen] = useState(false);
    const [menuOpen, setMenuOpen] = useState(false); // State to manage menu visibility on small screens
    const dropdownTimeoutRef = useRef(null);
    const headerRef = useRef(null);
    const [isUpArrowRed, setIsUpArrowRed] = useState(false);
    

    const location = useLocation();
    const [activeNavItem, setActiveNavItem] = useState('/');
    useEffect(() => {
        setActiveNavItem(location.pathname);
    }, [location]);

    const toggleDropdown = () => {
        setDropdownOpen(!dropdownOpen);
    };

    const toggleMenu = () => {
        setMenuOpen(!menuOpen); // Toggle the menu visibility
    };

    const handleScroll = (id) => {
        const anchor = document.getElementById(id);
        if (anchor) {
            anchor.scrollIntoView({ behavior: "smooth" });
        }
    };

    const handleMouseEnter = () => {
        if (dropdownTimeoutRef.current) {
            clearTimeout(dropdownTimeoutRef.current);
        }
        setDropdownOpen(true);
        setIsUpArrowRed(true)
    };

    const handleMouseLeave = () => {
        dropdownTimeoutRef.current = setTimeout(() => {
            setDropdownOpen(false);
        }, 200); // Adjust the delay as needed
    };

    const handleClickOutside = (event) => {
        if (headerRef.current && !headerRef.current.contains(event.target)) {
            setDropdownOpen(false);
        }
    };

    const handleNavLinkClick = () => {
        setMenuOpen(false);
        setDropdownOpen(false);
    };

    const handleDropdownClick = () => {
        // setActiveNavItem('/other');
        toggleDropdown();
    };

    useEffect(() => {
        document.addEventListener('mousedown', handleClickOutside);
        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, []);

    return (
        <header ref={headerRef} className="header-container container">
            <nav className="nav">
                <Link to="/">
                    <img src={logo} alt="logo" className="header-logo-img"/>
                </Link>

                <div className='toggle-container'>
                    <button className="menu-toggle" onClick={toggleMenu}>
                        ☰
                    </button>
                </div>

                <ul className={`nav-list ${menuOpen ? 'show' : ''}`}>
                    <li
                        className={`nav-item ${activeNavItem === '/' ? 'active-nav-item' : ''}`} onClick={() => setActiveNavItem('/')}
                    >
                        <Link className="nav-link" to="/" onClick={handleNavLinkClick}>
                            Главная
                        </Link>
                    </li>
                    <li
                        className={`nav-item ${activeNavItem === '/aboutBank' ? 'active-nav-item' : ''}`} onClick={() => setActiveNavItem('/aboutBank')}
                    >
                        <Link className="nav-link" to="/aboutBank" onClick={handleNavLinkClick}>
                            О Банке
                        </Link>
                    </li>
                    <li
                        className={`nav-item ${activeNavItem === '/services' ? 'active-nav-item' : ''}`} onClick={() => setActiveNavItem('/services')}
                    >
                        <Link className="nav-link" to="/services" onClick={handleNavLinkClick}>
                            Тарифы и<br/>Услуги
                        </Link>
                    </li>
                    <li
                        className={`nav-item ${activeNavItem === '/corporate' ? 'active-nav-item' : ''}`} onClick={() => setActiveNavItem('/corporate')}
                    >
                        <Link className="nav-link" to="/corporate" onClick={handleNavLinkClick}>
                            Корпоративным<br/>клиентам
                        </Link>
                    </li>
                    <li
                        className={`nav-item ${activeNavItem === '/personal' ? 'active-nav-item' : ''}`} onClick={() => setActiveNavItem('/personal')}
                    >
                        <Link className="nav-link" to="/personal" onClick={handleNavLinkClick}>
                            Физическим<br/>клиентам
                        </Link>
                    </li>
                    <li
                        className={`nav-item ${activeNavItem === '/cards' ? 'active-nav-item' : ''}`} onClick={() => setActiveNavItem('/cards')}
                    >
                        <Link className="nav-link" to="/cards" onClick={handleNavLinkClick}>
                            Платежные<br/>Карты
                        </Link>
                    </li>
                    <li
                        className={`nav-item ${activeNavItem === '/crypto' ? 'active-nav-item' : ''}`} onClick={() => setActiveNavItem('/crypto')}
                    >
                        <Link className="nav-link" to="/crypto" onClick={handleNavLinkClick}>
                            Операции с криптовалютой
                        </Link>
                    </li>

                    <li
                        className={`nav-item dropdown ${activeNavItem === '/other' ? 'active-nav-item' : ''}`}
                        onMouseEnter={handleMouseEnter}
                        onMouseLeave={handleMouseLeave}
                        onClick={handleDropdownClick}
                    >
                        <div className='flex-row'>
                          <span className="nav-link">
                              Другие Услуги
                          </span>

                          <div className='arrow-container'
                              onMouseEnter={handleMouseEnter}
                              onMouseLeave={handleMouseLeave}
                              >
                              {dropdownOpen ?
                                  (<img src={activeNavItem === '/other'? arrowUpRed: isUpArrowRed? arrowUpRed:arrowUpBlue} alt='arrow-up' className='header-arrow'/> )
                                  :
                                  (<img src={activeNavItem === '/other'? arrowDownRed : arrowDownBlue} alt='arrowdown' className='header-arrow'/>)
                              }
                          </div>
                        </div>

                        {dropdownOpen && (
                            <ul className="dropdown-content" onMouseEnter={()=>setIsUpArrowRed(false)}>
                                <li>
                                    <NavLink to="/other#deposit-account"
                                             className='dropdown-nav-link'
                                             onClick={() => { handleScroll('deposit'); handleNavLinkClick(); }}
                                    >
                                        Депозитный счет
                                    </NavLink>
                                </li>
                                <li>
                                    <NavLink to="/other#cashing-checks"
                                             className='dropdown-nav-link'
                                             onClick={() => { handleScroll('cashing-checks'); handleNavLinkClick(); }}
                                    >
                                        Обналичивание банковских чеков
                                    </NavLink>
                                </li>
                                <li>
                                    <NavLink to="/other#international-acquiring"
                                             className='dropdown-nav-link'
                                             onClick={() => { handleScroll('international-acquiring'); handleNavLinkClick(); }}
                                    >
                                        Международный эквайринг
                                    </NavLink>
                                </li>
                                <li>
                                    <NavLink to="/other#operations-metals"
                                             className='dropdown-nav-link'
                                             onClick={() => { handleScroll('operations-metals'); handleNavLinkClick(); }}
                                    >
                                        Операции с драгоценными металлами
                                    </NavLink>
                                </li>
                                <li>
                                    <NavLink to="/other#sberkassa-fx"
                                             className='dropdown-nav-link'
                                             onClick={() => { handleScroll('sberkassa-fx'); handleNavLinkClick(); }}
                                    >
                                        Международная СберКасса FX
                                    </NavLink>
                                </li>
                            </ul>
                        )}
                    </li>

                    <RedBtn btnLable='Войти' btnLink='/login' />
                </ul>
            </nav>
        </header>
    );
};

export default Header;
