import React, { useState, useEffect } from 'react';
import RedArrowLeft from '../../Assets/commonIcons/arrowRedLeft.svg';
import RedArrowRight from '../../Assets/commonIcons/arrowRedRight.svg';
import RedArrowLeftHover from '../../Assets/commonIcons/arrowFilledLeft.svg';
import RedArrowRightHover from '../../Assets/commonIcons/arrowFilledRight.svg';
import './newSlider.css';
import { Link } from 'react-router-dom';

const NewSlider = ({ slidesData }) => {
  const [currentSlide, setCurrentSlide] = useState(0);
  const [leftArrow, setLeftArrow] = useState(RedArrowLeft);
  const [rightArrow, setRightArrow] = useState(RedArrowRight);
  const [fadeClass, setFadeClass] = useState('');

  const handlePrev = () => {
    setCurrentSlide(prevSlide => (prevSlide === 0 ? slidesData.length - 1 : prevSlide - 1));
  };

  const handleNext = () => {
    setCurrentSlide(prevSlide => (prevSlide === slidesData.length - 1 ? 0 : prevSlide + 1));
  };

  const handleLeftArrowEnter = () => {
    setLeftArrow(RedArrowLeftHover);
  };

  const handleLeftArrowLeave = () => {
    setLeftArrow(RedArrowLeft);
  };

  const handleRightArrowEnter = () => {
    setRightArrow(RedArrowRightHover);
  };

  const handleRightArrowLeave = () => {
    setRightArrow(RedArrowRight);
  };

  const slide = slidesData[currentSlide];

  // Trigger fade-in effect when the slide changes
  useEffect(() => {
    setFadeClass('');  // Reset the class before the transition
    const timer = setTimeout(() => {
      setFadeClass('fade-in'); // Apply fade-in after the reset
    }, 50);  // Small delay to trigger the animation

    return () => clearTimeout(timer); // Clean up the timer on component unmount
  }, [currentSlide]);

  // Trigger fade-in effect when the slide changes
  useEffect(() => {
    setFadeClass('');  // Reset the class before the transition
    const timer = setTimeout(() => {
      setFadeClass('fade-in'); // Apply fade-in after the reset
    }, 50);  // Small delay to trigger the animation

    return () => clearTimeout(timer); // Clean up the timer on component unmount
  }, [currentSlide]);

  return (
    <div className={`slider-container ${fadeClass}`}>
      <div className="arrowImg">
        <div onClick={handlePrev} onMouseEnter={handleLeftArrowEnter} onMouseLeave={handleLeftArrowLeave}>
          <img src={leftArrow} alt="Previous" />
        </div>
        <div onClick={handleNext} onMouseEnter={handleRightArrowEnter} onMouseLeave={handleRightArrowLeave}>
          <img src={rightArrow} alt="Next" />
        </div>
      </div>
      <div className="taptopay-container">
        <div className="txt-col">
          {slide.items.map((item, index) => (
            <Link to={item.link} className='card_link txt-bx' key={index}>
                <h3>{item.title}</h3>
                <p>{item.text}</p>
            </Link>
          ))}
        </div>
        <div className="img-col">
          <img src={slide.image} alt="Slide Visual" />
        </div>
      </div>
    </div>
  );
};

export default NewSlider;
