import React, { useEffect } from 'react'
import s from './aboutBank.module.css'
import HeroWithBanner from '../hero-with-banner/HeroWithBanner'
import RedBtn from '../ui/redBtn/RedBtn'
import heroBanner from '../Assets/bannerImages/aboutBanner.svg'
import RobotHand from '../Assets/robot-hand.png'

function AboutBank() {
  const heroData = {
    heroImage: heroBanner,
    title: 'о банке ',
    desc: 'Международная СберКасса — это современный Банк, обслуживающий индивидуальных и корпоративных клиентов различных форм собственности и направлений деятельности. </br></br>Банк специализируется на работе с клиентами повышенного риска, предоставляет возможность открывать счета онлайн, обеспечивая удобство и безопасность для своих клиентов.',
    btnLable: 'Открыть счет',
    btnLink: '/register',
    bgGradient: false,
    textLeft: false,
  }

  return (
    <div className={s.about_page}>
      {/* hero section */}
      <div className={s.hero}>
        <HeroWithBanner {...heroData} />
      </div>

      {/* innovative solutions block */}
      <div class={`${s.innovative_solutions} container`}>
        {/* left side */}
        <div class={s.left}>
          <h2>Банк, предлагающий инновационные решения</h2>
          <div className={s.desc}>
            <p>
              Развитие бизнеса Банка строится на предоставлении клиентам широкого спектра высокотехнологических банковских
              продуктов и услуг на уровне международных стандартов.
            </p>
            <p>
              Международная СберКасса постоянно изучает и прогнозирует потребности клиентов, осуществляет поиск новых решений,
              применяет передовые технические средства хранения, защиты, обработки и передачи информации, минимизирует
              бюрократические процедуры, что позволяет максимально сократить время прохождения проверок, платежей и всех видов
              расчетов
            </p>
            <p>
              В основе взаимоотношений Банка с клиентами лежит принцип партнерства. Банк ориентирован на долгосрочное
              и взаимовыгодное сотрудничество.
            </p>
          </div>
          {/* btn for lg devices only */}
          <div className={s.btn}>
            <RedBtn btnLable='Открыть счет' btnLink='/register' arrow />
          </div>
        </div>

        {/* right side */}
        <div class={s.right}>
          <img className={s.card_img} src={RobotHand} alt='Innovative Solution' />
          <div className={`${s.card} ${s.gradient_bg} ${s.card_1}`}>
            <h4>01</h4>
            <p>
              Банковский надзор за деятельностью финансовой организации «Международная СберКасса», рег. No: B2023080, осуществляет
              Mwali International Services Authority, BP 724, г. Фомбони, автономный регион Союза Коморских Островов Мвали,
              Восточная Африка.
            </p>
          </div>
          <div className={`${s.card} ${s.card_2}`}>
            <h4>02</h4>
            <p>Уставной капитал Банка 1,437,714,201.00 Долларов США</p>
          </div>
          <div className={`${s.card} ${s.gradient_bg} ${s.card_3}`}>
            <h4>03</h4>
            <p>
              Контактные данные учреждения по надзору финансовых институтов на территории Коморских Островов:
              www.mwaliregistrar.com misa@mwaliregistrar.com Звонки принимаются круглосуточно.
            </p>
          </div>
          <div className={s.btn}>
            <RedBtn btnLable='Открыть счет' btnLink='/register' arrow />
          </div>
        </div>
      </div>

      {/* quote container */}
      <div className={`${s.quote} container`}>
        <p>
          Международная СберКасса <span>принимает заявки</span> на открытие счетов <span>онлайн</span>
        </p>
      </div>
    </div>
  )
}

export default AboutBank
